import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Alert } from 'antd';
import logo from '../../../assets/images/logo/indigo_vizual_logo.png';

import { webAuth0 } from '../../../utils/auth0';

import './signin.css';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';

import FormErrorMessage from '../../../components/FormErrorMessage';
import config from '../../../routing/config';
import SocialLogin from '../../../components/SocialLogin';
import CreateAccountPayload from '../../../api/endpoints/auth/interfaces/CreateAccountPayload.interface';

interface FormInputs {
  email: string;
  password: string;
  repeatPassword: string;
}

export default () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGPCLocked, setIsGPCLocked] = useState(false);
  const [companyNameMessage, setCompanyNameMessage] = useState<string>('');

  // hardcode for now
  const isItAGPCEmail = (email: string) => {
    try {
      const emailDomain = email.split('@')[1];
      if (emailDomain === 'genpt.com' || emailDomain === 'napastore.com') {
        setCompanyNameMessage('GPC');
      }
      if (emailDomain === 'adspipe.com') {
        setCompanyNameMessage('ADS');
      }
      return (
        emailDomain === 'genpt.com' ||
        emailDomain === 'napastore.com' ||
        emailDomain === 'adspipe.com'
      );
    } catch (error) {
      return false;
    }
  };

  const validationSchema = validation
    .object({
      email: validation
        .string()
        .required(t('signup.email'))
        .email(t('signup.emailValid'))
        .defined(),
      password: validation.string().required(t('signup.password')).defined(),
    })
    .defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const [email, setEmail] = useStateWithHookForm<Partial<FormInputs>, string>(
    { setValue, trigger, name: 'email' },
    '',
  );

  const handleEmailChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);

    if (isItAGPCEmail(value) && !isGPCLocked) {
      setIsGPCLocked(true);
    } else if (isGPCLocked && !isItAGPCEmail(value)) {
      setIsGPCLocked(false);
    }
  };

  const [password, setPassword] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'password' }, '');

  const handlePasswordChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };

  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    if (isGPCLocked) return;
    setIsLoading(true);

    const payload: CreateAccountPayload = {
      email: inputsData?.email ?? '',
      password: inputsData?.password ?? '',
    };

    try {
      webAuth0.login(
        {
          realm: 'Username-Password-Authentication',
          username: payload.email,
          password: payload.password,
          responseType: 'token id_token',
          scope: 'roles openid profile email',
        },
        (err: any, authResult: any) => {
          setIsLoading(false);

          if (err) {
            console.log(err);
            toast.error(err.description);
            return;
          }
          console.log(authResult);
        },
      );
    } catch (error: any) {
      console.log(error);
      toast.error(error.description);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ call submit function here
        handleSubmit(handleSubmitData)();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <form className="form w-100 position-relative" id="kt_sign_up_form">
      <div className="formLogo mb-11 text-center">
        <img alt="Logo" src={logo} width="170" />
      </div>
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{t('auth_pages.signin')}</h1>
      </div>
      <div className="fv-row mb-8">
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage name="email" errors={errors} className="my-1 px-2" />
        {isGPCLocked ? (
          <Alert
            message={`${companyNameMessage} users can only sign in using the Microsoft button below.`}
            type="info"
            showIcon
          />
        ) : null}
      </div>
      {isGPCLocked ? null : (
        <>
          <div className="fv-row mb-8" data-kt-password-meter="true">
            <div className="mb-1">
              <div className="position-relative mb-3">
                <input
                  className="form-control bg-transparent"
                  type={viewPassword ? 'text' : 'password'}
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="none"
                  disabled={isGPCLocked}
                />
                <span
                  tabIndex={-1}
                  role="button"
                  onKeyDown={() => setViewPassword(!viewPassword)}
                  onClick={() => setViewPassword(!viewPassword)}
                  className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                  data-kt-password-meter-control="visibility"
                >
                  <i
                    className={clsx(
                      'bi bi-eye-slash fs-2',
                      !viewPassword ? 'd-none' : '',
                    )}
                  />
                  <i
                    className={clsx(
                      'bi bi-eye fs-2',
                      viewPassword ? 'd-none' : '',
                    )}
                  />
                </span>
              </div>
              <FormErrorMessage
                name="password"
                errors={errors}
                className="my-1 px-2"
              />
              <div className="my-1 px-2">
                <Link
                  to={config.forgotPassword.route}
                  className="btn btn-link btn-color-muted btn-active-color-primary me-5 mb-2"
                >
                  {t('auth_pages.forgot_password?')}
                </Link>
              </div>
            </div>
          </div>
          <div className="d-grid mb-10">
            <button
              disabled={isLoading || isGPCLocked}
              onClick={handleSubmit(handleSubmitData)}
              type="button"
              id="kt_sign_up_submit"
              className="btn btn-purple"
            >
              {!isLoading ? (
                <span className="indicator-label">
                  {t('auth_pages.signin')}
                </span>
              ) : (
                <span className="d-block indicator-progress">
                  {t('auth_pages.wait')}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              )}
            </button>
          </div>
        </>
      )}
      <SocialLogin isGPCLocked={isGPCLocked} />

      {isGPCLocked ? null : (
        <div className="text-gray-500 text-center fw-semibold fs-6">
          {t('auth_pages.no_account')}{' '}
          <Link
            to={config.signupPassword.route}
            className="link-primary fw-semibold"
          >
            {t('auth_pages.signup')}
          </Link>
        </div>
      )}
    </form>
  );
};
