import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalState from '../../../../../store/reducers/globalState.interface';

export default ({ connected = false }: { connected?: boolean }) => {
  const user = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();

  const connectURL = `${process.env.REACT_APP_POWER_API_URL}/signup/${user.id}`;
  const disconnectURL = `${process.env.REACT_APP_POWER_API_URL}/signoutUser/${user.id}`;

  const styles = {
    marginLeft: '15px',
  };
  return (
    <>
      {connected && (
        <span>
          <i className="fas fa-check-circle text-success me-2" />
          {t('common.connected')}
        </span>
      )}
      <a
        href={connected ? disconnectURL : connectURL}
        target="_blank"
        rel="noreferrer"
        style={styles}
        className="btn btn-primary"
      >
        {connected ? t('common.disconnect') : t('common.connect')}
      </a>
    </>
  );
};
